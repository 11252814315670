<!--
 * @Description:我的 个人信息 实名认证 authenticateSelf
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-31 10:15:34
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <van-nav-bar title="实名认证"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-form @submit="authenticationClick"
              class="form">
      <van-row class="content">
        <van-row class="content-lineone">
          <van-col class="content-lineone-left">
            <van-row class="content-lineone-left-lineone">
              填写身份信息进行身份验证
            </van-row>
            <van-row class="content-lineone-left-linetwo">
              验证通过方能使用全部功能
            </van-row>
          </van-col>
          <van-col class="content-lineone-right">
            <img src="@/assets/mySelf/authenticateSelf.png"
                 alt="">
          </van-col>
        </van-row>
        <van-row class="content-linetwoText">
          姓名
        </van-row>
        <van-row class="content-linetwo">
          <van-field v-model="userName"
                     :rules="[{ required: true}]"
                     style="border-bottom:1px solid #E5E5E5;padding-left: 0px"
                     placeholder="请输入真实姓名" />
        </van-row>
        <van-row class="content-linethreeText">
          身份证
        </van-row>
        <van-row class="content-linethree">
          <van-field v-model="userIdCard"
                     :rules="[{ required: true}]"
                     style="border-bottom:1px solid #E5E5E5;padding-left: 0px"
                     placeholder="请输入身份证号" />
        </van-row>
      </van-row>
      <!-- 图片缺口滑块 -->
      <van-row>
        <Vcode :show="isShow"
               @success="success" />
      </van-row>
      <van-button class="bottom-button"
                  native-type="submit">认 证</van-button>
    </van-form>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import Vcode from 'vue-puzzle-vcode'// 页面内引用插件
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    Vcode
  },
  data () {
    // 这里存放数据
    return {
      userName: '', // 用户名
      userIdCard: '', // 用户身份证
      isShow: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    success () {
      this.isShow = false // 通过验证后，手动隐藏模态框
      this.authentication()
    },
    // 点击认证 实名认证
    authenticationClick () {
      console.log(this.userName, this.userIdCard)
      this.isShow = true
    },
    // 认证调取
    authentication () {
      this.$router.go(-1)
      // const info = {
      //   realName: this.userName,
      //   cardNo: this.userIdCard
      // }
      // this.$authenticateSelf.idIdentify(info).then(() => {
      //   this.$router.go(-1)
      //   this.$toast.success('认证成功！')
      // })
    },
    // 顶部返回按钮
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .form {
    width: 100%;
    height: 100%;
    .content {
      width: 93.6%;
      height: 40%;
      margin-left: 3.2%;
      margin-top: 2%;
      background: #ffffff;
      .content-lineone {
        width: 100%;
        height: 32%;
        background: #19a9fc;
        font-family: PingFang-SC-Medium;
        color: #fefeff;
        font-size: 17px;
        .content-lineone-left {
          padding-left: 3.56%;
          width: 66.81%;
          height: 100%;
          .content-lineone-left-lineone {
            padding-top: 15px;
          }
          .content-lineone-left-linetwo {
            margin-top: 5px;
          }
        }
        .content-lineone-right {
          width: 33.19%;
          height: 100%;
        }
      }
      .content-linetwoText {
        padding-left: 3.56%;
        font-family: PingFang-SC-Regular;
        color: #333333;
        font-size: 15px;
        margin-top: 3.5%;
      }
      .content-linetwo {
        width: 93.6%;
        margin-left: 3.2%;
        margin-top: 0.5%;
      }
      .content-linethreeText {
        padding-left: 3.56%;
        font-family: PingFang-SC-Regular;
        color: #333333;
        font-size: 15px;
        margin-top: 3.5%;
      }
      .content-linethree {
        width: 93.6%;
        margin-left: 3.2%;
        margin-top: 0.5%;
      }
    }
    .bottom-button {
      width: 93.6%;
      height: 5.5%;
      margin-left: 3.2%;
      margin-top: 5%;
      background: #19a9fc;
      color: #ffffff;
      font-family: PingFang-SC-Regular;
      font-size: 17px;
      border-radius: 5px;
    }
  }
}
</style>
